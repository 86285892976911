import { Controller } from "@hotwired/stimulus";

export default class RemoveFormElementController extends Controller {

    static targets = ["element"]
    declare readonly elementTarget:HTMLElement

    removeElement(event:Event) {
        this.elementTarget.remove()
    }
}
