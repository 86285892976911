import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

interface DateSelectedDetail {
    date?: Date;
}
export default class DatePickerController extends Controller {
    static targets = ["input"]
    declare readonly inputTarget:HTMLElement

    connect() {
        flatpickr(
            this.inputTarget,
            {
                dateFormat: "d-m-Y"
            }
        )
    }
}
