import { Controller } from "@hotwired/stimulus";

export default class ClearInputController extends Controller {

    static targets = ["input", "select"]
    declare readonly inputTargets:[HTMLInputElement];
    declare readonly selectTargets: [HTMLSelectElement];
    clearInput() {
        this.inputTargets.forEach((element) => element.value = "")
    }
    clearSelect() {
        this.selectTargets.forEach((element) => element.selectedIndex = -1)
    }
}
