import { Controller } from "@hotwired/stimulus";

export default class SumController extends Controller {

    static targets = [ "operand", "resultLabel" ]
    declare readonly operandTargets:[HTMLFormElement];
    declare readonly resultLabelTarget:HTMLElement;

    connect() {
        // Do all computations after connect, in case the browser autofills the fields
        this.compute()
    }

    compute() {
        let result = this.operandTargets.reduce((total, formElement) => {
            return total + Number(formElement.value);
        }, 0);
        // Round to CHF 0.05
        result = Math.round(result * 20) / 20
        this.resultLabelTarget.innerText = result.toString()
    }
}
