import { Controller } from "@hotwired/stimulus";

export default class BillingFormController extends Controller {

    static targets = [ "externalForm", "internalForm", "checkbox" ]
    declare readonly externalFormTarget:HTMLFormElement;
    declare readonly internalFormTarget:HTMLFormElement;
    declare readonly checkboxTarget:HTMLInputElement;

    toggle(event:Event) {
        this.setFormVisibility()
    }
    connect() {
        this.setFormVisibility()
    }

    setFormVisibility() {
        const isInternal = this.checkboxTarget.checked
        this.externalFormTarget.hidden = isInternal
        this.internalFormTarget.hidden = !isInternal
    }
}
