// See https://marcoroth.dev/posts/guide-to-custom-turbo-stream-actions

import { StreamActions, visit } from "@hotwired/turbo"

StreamActions.redirect_to = function () {
    const urlString = this.getAttribute("url") as string
    // We're not using Turbo.visit here intentionally
    // The problem is the Angular Apps that are used in OpenProject. Their navigation breaks with Turbo visit, or Turbo Drive.
    // That's why turbo drive is disabled in OP

    // For now just reload the entire page
    location.href = urlString
};