import { Controller } from "@hotwired/stimulus";

export default class ClearFormController extends Controller {

    static targets = ["form"]
    declare readonly formTarget:HTMLFormElement;
    connect() {
        this.formTarget.reset()
    }

    clear() {
        this.formTarget.reset()
    }
}
