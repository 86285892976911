import { Controller } from "@hotwired/stimulus";

export default class MultiplierController extends Controller {

    static targets = [ "operand", "resultLabel", "resultField" ]
    declare readonly operandTargets:[HTMLFormElement];
    // Just displays the result
    declare readonly resultLabelTarget:HTMLElement;
    // Stores the result as a numeric value (in a form field) for other controllers to use in their computations
    declare readonly resultFieldTarget:HTMLFormElement;

    connect() {
        // Do all computations after connect, in case the browser autofills the fields
        this.compute()
    }

    compute() {
        const result = this.operandTargets.reduce((total, formElement) => {
            return total * Number(formElement.value);
        }, 1);
        const roundedResult = Math.round(result * 20) / 20
        this.resultLabelTarget.innerText = roundedResult.toString()
        this.resultFieldTarget.value = result
        this.dispatch("compute")
    }
}
