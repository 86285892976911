import { Controller } from "@hotwired/stimulus";

export default class AddFormElementController extends Controller {

    static targets = ["template", "templateOutput"]
    declare readonly templateTarget:HTMLElement
    declare readonly templateOutputTarget:HTMLElement

    addElement(event:Event) {

        // Get the template HTML from the DOM and copy it
        const template = this.templateTarget.cloneNode(true) as HTMLElement

        // Get the HTML as string and replace all the placeholders __NAME__, ...
        var templateString = template.innerHTML

        // Also set a unique index for the sub-form, otherwise the rails magic of submitting all resources as an array won't work
        const time = new Date().getTime();
        templateString = templateString.replace(/__TEMPLATE__/g, time.toString())
        // debugger
        // Insert into DOM
        this.templateOutputTarget.insertAdjacentHTML("beforeend", templateString)
    }
}
