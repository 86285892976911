import { Controller } from "@hotwired/stimulus";

export default class QuoteMessageController extends Controller {

    static targets = ["textArea", "pipeline"]
    declare readonly textAreaTarget:HTMLFormElement
    declare readonly pipelineTarget:HTMLFormElement

    connect() {
        // Create the messages when the pages loads
        // If the textArea is empty, initialize it to the selected pipeline
        // This way an existing message (after validation errors) won't be overwritten
        if (this.textAreaTarget.value.length == 0) {
            this.pipelineSelected()
        }
    }

    pipelineSelected() {
        console.log("HUH")
        const selectedOption = this.pipelineTarget.options[this.pipelineTarget.selectedIndex]
        var message = selectedOption.dataset.descriptionForQuote

        if (message == null) {
            message = ""
        }
        this.textAreaTarget.value = message.toString()
    }
}
