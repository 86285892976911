import { Controller } from "@hotwired/stimulus";

export default class SubmitFormController extends Controller {

    static targets = [ "form" ]
    declare readonly formTarget:HTMLFormElement;
    declare timeout: number
    changed(event:Event) {
        // Get debounce time from target or use default
        let debounce = 300
        let target = event.target as HTMLInputElement
        if (target.dataset.formTargetDebounceMs != null) {
            debounce = parseInt(target.dataset.formTargetDebounceMs,10)
        }

        const confirmMessage = this.formTarget.dataset.confirmMessage
        // If a confirmMessage is set on the form, confirm before submit
        if (confirmMessage != null) {
            const shouldSubmit = confirm(confirmMessage)
            if (shouldSubmit) {
                this.submit(debounce)
            } else {
                this.formTarget.reset()
            }
        } else {
            this.submit(debounce)
        }
    }
    submit(debounce: number) {
        // Debounce submit to reduce server load
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.formTarget.requestSubmit();
        }, debounce);
    }
}
