import { Application, defaultSchema } from '@hotwired/stimulus'

import BillingFormController  from "./controllers/billing-form.controller";
import SubmitFormController from "./controllers/submit-form.controller";
import ClearInputController from "./controllers/clear-input.controller"
import ClearFormController from "./controllers/clear-form.controller";
import AddFormElementController from "./controllers/add-form-element.controller"
import RemoveFormElementController from "./controllers/remove-form-element.controller";
import MultiplierController from "./controllers/multiplier.controller";
import SumController from "./controllers/sum.controller";
import QuoteMessageController from "./controllers/quote-message.controller";
import DatePickerController from "./controllers/date-picker.controller";
declare global {
    interface Window {
        StimulusIBU:Application
    }
}

const instance = Application.start()
window.StimulusIBU = instance

instance.handleError = (error, message, detail) => {
    console.warn(error, message, detail)
}

instance.register("billing-form", BillingFormController)
instance.register("clear-input", ClearInputController)
instance.register("clear-form", ClearFormController)
instance.register("submit-form", SubmitFormController)
instance.register("add-form-element", AddFormElementController)
instance.register("remove-form-element", RemoveFormElementController)
instance.register("multiplier", MultiplierController)
instance.register("sum", SumController)
instance.register("quote-message", QuoteMessageController)
instance.register("date-picker", DatePickerController)
